import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import CardButton from "../../Components/Buttons/card-button";

import renderManageCard from "../../Organisms/Modals/Manage Card/manage-card";
import renderFeatureUnavailable from "../../Organisms/Modals/Banking/feature-unavailable";
import renderAccountDetails from "../../Organisms/Modals/Banking/account-details";
import renderCashOutBankNotConnected from "../../Organisms/Modals/Banking/cash-out-bank-not-connected";
import renderRequestVirtualCard from "../../Organisms/Modals/Virtual Card/request-virtual-card";
import renderVerifyToViewVirtualCard from "../../Organisms/Modals/Virtual Card/verify-to-view-virtual-card";

import { useModalContext } from "../../Contexts/ModalContext";

import { hasSVDPhysicalCard, isSVDApproved } from "../../Utils/helpers";

import { BankInfo, BankingOutline, Card, Out, Recurring, Statement, SupportThin, Thunder } from "../../Assets/Icons";

export default function BankingServices({ connectedAccounts = {}, stakeDebit = {}, payrollAccounts = {} }) {
  const modalContext = useModalContext();
  const navigate = useNavigate();

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts?.data?.data?.accounts]);

  const IsSVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  const HasSVDPhysicalCard = useMemo(() => hasSVDPhysicalCard(stakeDebit?.data), [stakeDebit?.data]);

  const isPayrollConnected = useMemo(() => {
    return payrollAccounts?.data?.data?.accounts?.length > 0;
  }, [payrollAccounts]);

  const handleManageVirtualCardClick = () => {
    if (
      !!stakeDebit?.data?.data?.virtual_card_id &&
      !!stakeDebit?.data?.data?.virtual_card_expiration &&
      !!stakeDebit?.data?.data?.virtual_card_last_4
    ) {
      // All values contain a non-empty value
      renderVerifyToViewVirtualCard(modalContext);
    } else {
      // At least one value is empty
      renderRequestVirtualCard(modalContext);
    }
  };

  const handleOnCashOutClick = () => {
    if (!isBankAccountConnected) {
      renderCashOutBankNotConnected(modalContext);
    } else {
      navigate("/banking/cash-out");
    }
  };

  return (
    <div className="banking-service-buttons row">
      <div className="col-sm-6 col-12 px-sm-2 px-0">
        <CardButton
          title="Connected Bank Accounts"
          Icon={BankingOutline}
          onClick={navigate.bind(this, "/banking/connected-accounts")}
        />
      </div>

      {IsSVDApproved && (
        <>
          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <CardButton
              title="Recurring Deposits"
              Icon={Recurring}
              onClick={renderFeatureUnavailable.bind(this, modalContext, "Recurring Deposits")}
            />
          </div>

          {isPayrollConnected && (
            <div className="col-sm-6 col-12 px-sm-2 px-0">
              <CardButton
                title="Express Paycheck"
                Icon={Thunder}
                onClick={navigate.bind(this, "/banking/direct-deposit")}
              />
            </div>
          )}

          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <CardButton title="Bank Statements" Icon={Statement} onClick={navigate.bind(this, "/banking/statement")} />
          </div>

          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <CardButton title="Manage Virtual card" Icon={Card} onClick={handleManageVirtualCardClick} />
          </div>

          {HasSVDPhysicalCard && (
            <div className="col-sm-6 col-12 px-sm-2 px-0">
              <CardButton
                title="Manage Physical card"
                Icon={Card}
                onClick={renderManageCard.bind(this, modalContext)}
              />
            </div>
          )}

          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <CardButton
              title="Account and routing number"
              Icon={BankInfo}
              onClick={renderAccountDetails.bind(this, modalContext, stakeDebit?.data)}
            />
          </div>

          <div className="col-sm-6 col-12 px-sm-2 px-0">
            <CardButton
              title="Banking Limits"
              Icon={SupportThin}
              onClick={navigate.bind(this, "/banking/banking-limits")}
            />
          </div>
        </>
      )}

      <div className="col-sm-6 col-12 px-sm-2 px-0">
        <CardButton title="Cash Out" Icon={Out} onClick={handleOnCashOutClick} />
      </div>
    </div>
  );
}
