import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";

import AmountField from "../../Components/Form Fields/amount-field";
import PrimaryButton from "../../Components/Buttons/primary-button";
import FormSwitch from "../../Components/Form Fields/switch";

import renderAuthorizeTransfer from "../../Organisms/Modals/Banking/authorize-transfer";
import renderStatusModal from "../../Organisms/Modals/status-modal";
import renderFeatureUnavailable from "../../Organisms/Modals/Banking/feature-unavailable";

import withFormProvider from "../../HOC/withFormProvider";

import { useConnectedAccounts, useConnectedAccountSelection, useStakeDebit, useDeals } from "../../CustomHooks/Queries";
import { useInitiateACHDebit } from "../../CustomHooks/Mutations";

import { useModalContext } from "../../Contexts/ModalContext";
import { useAppUtilityContext } from "../../Contexts/AppUtilityContext";

import {
  clearLocationHistory,
  dispatchErrorToast,
  formatCurrency,
  getTransferTypes,
  isSVDApproved,
} from "../../Utils/helpers";
import { FailedTransferStatusModalProps, SuccessfulTransferStatusModalProps } from "../../Utils/constants";

import { ArrowLeftCircle, Close, Edit } from "../../Assets/Icons";

function AddCash() {
  const modalContext = useModalContext();

  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const { enforceAddCash = false } = locationState ?? {};

  const { appUtilityCallbacks, setAppUtilityCallbacks } = useAppUtilityContext();

  const { watch, handleSubmit } = useFormContext();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const {
    isLoading: isLoadingConnectedAccounts,
    data: connectedAccounts,
    isError: isConnectedAccountsFetchError,
  } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) => account.account_id === connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [connectedAccounts?.data?.data?.accounts, connectedAccountSelection?.data?.data?.selected]
  );

  const SVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  const accountDescription = useMemo(() => {
    return `${selectedAccount?.name} (...${selectedAccount?.mask})`;
  }, [selectedAccount]);

  const dailyAddFundsLimit = useMemo(() => {
    const dailyLimit = (stakeDebit?.data?.data?.account_limits?.daily_add_cash_limit ?? 0) / 100;
    return dailyLimit;
  }, [stakeDebit]);

  const transferTypes = useMemo(() => getTransferTypes(deals?.data), [deals]);

  useEffect(() => {
    if (isConnectedAccountsFetchError || (!isLoadingStakeDebit && !SVDApproved)) {
      navigate("/banking");
    }
  }, [isConnectedAccountsFetchError, isLoadingStakeDebit, SVDApproved, navigate]);

  const { mutate: InitiateACHDebit } = useInitiateACHDebit({
    onSuccess: () =>
      renderStatusModal(modalContext, {
        ...SuccessfulTransferStatusModalProps,
        description: `Your transfer is expected to arrive on ${deals?.data?.content?.transaction_clearing_date}.`,
        onCTAButtonClick: enforceAddCash
          ? () => {
              setAppUtilityCallbacks({});
              clearLocationHistory();
              appUtilityCallbacks.onAddCashSuccessful();
            }
          : () => {
              modalContext.onModalClose();
              navigate("/banking");
            },
      }),
    onError: () =>
      renderStatusModal(modalContext, {
        ...FailedTransferStatusModalProps,
        onCTAButtonClick: modalContext.onModalClose,
      }),
  });

  const cashAmount = parseFloat(watch("cash-amount")?.unmasked ?? "");

  const disableSubmitButton = isNaN(cashAmount) || cashAmount === 0;

  const onSubmit = () => {
    if (!selectedAccount) {
      dispatchErrorToast("Please add a bank account to proceed", {
        alignToAppContent: true,
        key: "noConnectedAccount",
      });
    } else if (dailyAddFundsLimit > 0 && cashAmount > dailyAddFundsLimit) {
      dispatchErrorToast("Amount cannot exceed the daily limit.", {
        alignToAppContent: true,
        key: "amountExceedsThreshold",
      });
    } else {
      renderAuthorizeTransfer(modalContext, {
        stake_account_number_last4: stakeDebit?.data?.data?.account_number?.slice(-4),
        stake_routing_number: stakeDebit?.data?.data?.routing_number,
        selectedAccount,
        clearingTransactionDate: deals?.data?.content?.transaction_clearing_date,
        amount: cashAmount,
        AuthorizeCallback: () =>
          InitiateACHDebit({
            accountId: selectedAccount.account_id,
            amount: cashAmount,
          }),
      });
    }
  };

  const handleBackButtonClick = () => {
    if (enforceAddCash) {
      setAppUtilityCallbacks({});
      clearLocationHistory();
      appUtilityCallbacks.onBackCallBack();
    } else {
      navigate(-1);
    }
  };

  const handleChangeTransferType = (selectedType) => {
    selectedType === transferTypes[0].type && renderFeatureUnavailable(modalContext, "Instant Transfer");
  };

  const isLoading =
    isLoadingDeals || isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingConnectedAccountSelection;

  return (
    <div className={`add-cash${enforceAddCash ? " elevate" : ""}`}>
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={handleBackButtonClick}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Add Cash</h3>
              {enforceAddCash ? (
                <button
                  className="close-button"
                  onClick={() => {
                    setAppUtilityCallbacks({});
                    clearLocationHistory();
                    appUtilityCallbacks.onAbortCallBack();
                  }}
                >
                  <Close />
                </button>
              ) : (
                <div className="dummy"></div>
              )}
            </div>
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <form className="content mt-sm-12 mt-6" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="top-container">
                  <AmountField />
                  {!!dailyAddFundsLimit && (
                    <span className="daily-limit-label mt-2">Daily limit: {formatCurrency(dailyAddFundsLimit)}</span>
                  )}
                </div>
                <div className="bottom-container">
                  {deals?.data?.data?.currentDeal?.enabled_for_instant_transfer__c && (
                    <div className="transfer-types mb-6">
                      {transferTypes.map((transferType, idx) => (
                        <button
                          key={idx}
                          className={`transfer-type ${transferType.type}${
                            transferType.type === transferTypes[1].type ? " selected" : ""
                          }`}
                          type="button"
                          onClick={handleChangeTransferType.bind(this, transferType.type)}
                        >
                          <span className="icon-wrapper">
                            <transferType.Icon className="icon" />
                          </span>
                          <div className="title">{transferType.title}</div>
                          <span className="description">{transferType.description}</span>
                        </button>
                      ))}
                    </div>
                  )}
                  <div className="details">
                    <div className="detail-row">
                      <span className="detail-label">Account</span>
                      <span className="detail-value">
                        {!!selectedAccount ? accountDescription : "Connect a bank account"}
                        <button
                          className="edit"
                          type="button"
                          onClick={navigate.bind(this, "/banking/connected-accounts")}
                        >
                          <Edit />
                        </button>
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Fee</span>
                      <span className="detail-value">Free</span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Make this recurring</span>
                      <FormSwitch
                        checked={false}
                        onClick={renderFeatureUnavailable.bind(this, modalContext, "Recurring Deposit")}
                      />
                    </div>
                    <div className="detail-row">
                      <div className="info">
                        2-3 business days. Transactions made after 3:00 PM ET or on a weekend or holiday will be
                        processed the next business day.
                      </div>
                    </div>
                  </div>
                  <PrimaryButton className="blue-theme mt-sm-6 mt-4" type="submit" disabled={disableSubmitButton}>
                    Add Cash
                  </PrimaryButton>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default withFormProvider(AddCash);
