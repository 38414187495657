import renderStatusModal from "../status-modal";

import { AlertOctogon } from "../../../Assets/Icons";

export function renderPayrollAdditionPending(modalContext) {
  const { onModalClose } = modalContext;

  renderStatusModal(modalContext, {
    StatusIcon: AlertOctogon,
    title: "Connection Pending",
    description: "Your account should be connected within 24 hours. we will send you a push notification.",
    CTAButtonText: "Ok",
    onCTAButtonClick: () => onModalClose(),
  });
}
