import React, { useMemo } from "react";

import BankAccountInformation from "./bank-account-information";
import BankingActionButtons from "./banking-action-buttons";
import BankingActivity from "./banking-activity";
import EarnMoreCards from "./earn-more-cards";
import BankingServices from "./banking-services";

import BankAccountInformationSkeleton from "../../Components/Skeleton/bank-account-information-skeleton";
import BankActionButtonsSkeleton from "../../Components/Skeleton/banking-action-buttons-skeleton";
import BankingActivitySkeleton from "../../Components/Skeleton/banking-activity-skeleton";
import EarnMoreCardSkeleton from "../../Components/Skeleton/earn-more-card-skeleton";

import {
  useDeals,
  useActivities,
  useStakeDebit,
  useConnectedAccounts,
  usePayrollAccounts,
} from "../../CustomHooks/Queries";

import { isSVDApproved } from "../../Utils/helpers";

import "./banking.scss";
import "./banking-responsive.scss";
import "./banking-skeleton.scss";

export default function Banking() {
  const { isLoading: isLoadingActivities, data: activitiesData } = useActivities();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingConnectedAccounts, data: ConnectedAccounts } = useConnectedAccounts();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const IsSVDApproved = useMemo(() => isSVDApproved(stakeDebit?.data), [stakeDebit?.data]);

  const { isLoading: isLoadingPayrollAccounts, data: payrollAccounts } = usePayrollAccounts({ enabled: IsSVDApproved });

  const isLoadingEarnMore =
    isLoadingDeals || isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingPayrollAccounts;

  const isLoadingBankingServices = isLoadingStakeDebit || isLoadingConnectedAccounts || isLoadingPayrollAccounts;

  const availableBalance = useMemo(() => {
    return stakeDebit?.data?.data?.available ?? 0;
  }, [stakeDebit]);

  return (
    <div className="banking">
      <section className="banking-details">
        <div className="max-width-container">
          {isLoadingActivities || isLoadingStakeDebit || !stakeDebit ? (
            <BankAccountInformationSkeleton />
          ) : (
            <BankAccountInformation
              currentBalance={activitiesData?.data?.data?.currentBalance}
              availableBalance={availableBalance}
              stakeDebit={stakeDebit}
            />
          )}
          {isLoadingStakeDebit || isLoadingConnectedAccounts ? (
            <BankActionButtonsSkeleton />
          ) : (
            <BankingActionButtons stakeDebit={stakeDebit} connectedAccounts={ConnectedAccounts} />
          )}
          {isLoadingActivities ? (
            <BankingActivitySkeleton />
          ) : (
            <BankingActivity bankActivityList={activitiesData ?? []} />
          )}
        </div>
      </section>
      <section className="earn-more-section">
        <div className="max-width-container">
          <div className="section-header">
            <h3 className="section-title">Earn More</h3>
          </div>
          {isLoadingEarnMore ? (
            <EarnMoreCardSkeleton />
          ) : (
            <EarnMoreCards connectedAccounts={ConnectedAccounts} stakeDebit={stakeDebit} deals={deals} />
          )}
        </div>
      </section>
      {!isLoadingBankingServices && (
        <section className="banking-services-section pt-0">
          <div className="max-width-container">
            <div className="section-header">
              <h3 className="section-title">Banking Services</h3>
            </div>
            <BankingServices
              connectedAccounts={ConnectedAccounts}
              stakeDebit={stakeDebit}
              payrollAccounts={payrollAccounts}
            />
          </div>
        </section>
      )}
    </div>
  );
}
