import PrimaryButton from "../../Components/Buttons/primary-button";

import { modalVariants } from "../../Utils/constants";

export default function renderStatusModal(
  modalContext,
  {
    StatusIcon,
    statusClassName,
    title,
    description,
    CTAButtonText,
    onCTAButtonClick,
    secondaryCTAButtonText,
    onSecondaryCTAButtonClick,
  }
) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => (
    <>
      {!!StatusIcon && <StatusIcon className={`modal-status-icon ${statusClassName} mb-sm-4 mb-6`} />}
      <h3 className="modal-status-title mb-2">{title}</h3>
      <span className="modal-content-text mb-6">{description}</span>
      <div className="action-container">
        <PrimaryButton className="blue-theme" onClick={onCTAButtonClick}>
          {CTAButtonText}
        </PrimaryButton>
        {!!onSecondaryCTAButtonClick && (
          <PrimaryButton className="secondary-colors" onClick={onSecondaryCTAButtonClick}>
            {secondaryCTAButtonText}
          </PrimaryButton>
        )}
      </div>
    </>
  ));
  setModalVariant(modalVariants.statusDialog);
  setModalTitle("");
}
