import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";

import { isSVDApproved } from "../../Utils/helpers";

export default function BankingActionButtons({ stakeDebit = {}, connectedAccounts = {} }) {
  const navigate = useNavigate();

  const isBankAccountConnected = useMemo(() => {
    return connectedAccounts?.data?.data?.accounts?.length > 0;
  }, [connectedAccounts?.data?.data?.accounts]);

  const IsSVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  if (!IsSVDApproved || !isBankAccountConnected) {
    return <></>;
  }

  return (
    <div className="banking-action-buttons">
      <button className="add-cash-button" onClick={navigate.bind(this, "/banking/add-cash")}>
        Add Cash
      </button>
      <Divider orientation="vertical" flexItem />
      <button className="cash-out-button" onClick={navigate.bind(this, "/banking/cash-out")}>
        Cash Out
      </button>
    </div>
  );
}
