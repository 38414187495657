import renderStatusModal from "../status-modal";

import { CheckCircleOutline } from "../../../Assets/Icons";

export function renderPayrollAdditionSuccess(modalContext) {
  const { onModalClose } = modalContext;

  renderStatusModal(modalContext, {
    StatusIcon: CheckCircleOutline,
    title: "Connection Successful",
    description: "Changes will take effect in 1-3 days",
    CTAButtonText: "Ok",
    onCTAButtonClick: () => onModalClose(),
  });
}
