import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CircularProgress } from "@mui/material";

import EarnMore from "../../Organisms/Earn More Card";
import renderAlertConfirmationModal from "../../Organisms/Modals/alert-confirmation-modal.js";

import { useModalContext } from "../../Contexts/ModalContext";

import { useConnectedAccounts, useConnectedAccountSelection } from "../../CustomHooks/Queries";

import { AddABank } from "../../Assets/Images";
import { ArrowLeftCircle, AddCircle, Trash, Tick } from "../../Assets/Icons";

import useConnectBankAccount from "../../CustomHooks/useConnectBankAccount.js";

import { useResetConnectedAccountSelection } from "../../CustomHooks/Mutations";

import { APIQueryKeys } from "../../Utils/constants.js";

import { dispatchAPIErrorToast } from "../../Utils/helpers.js";

import "./connected-accounts.scss";
import "./connected-accounts-responsive.scss";

const RemoveConnectedAccountsModalProps = {
  title: "Remove Account",
  description: "Are you sure you want to remove this account?",
  CTAButtonText: "Remove",
  secondaryCTAButtonText: "Cancel",
};

const BankAccountItem = ({ account, selected, saveAccountAsDefaultAccount }) => {
  const modalContext = useModalContext();

  const queryClient = useQueryClient();

  const { isLoading: isResettingConnectedAccount, mutate: ResetConnectedAccount } = useResetConnectedAccountSelection({
    onSuccess: () => {
      queryClient.refetchQueries(APIQueryKeys.connectedAccounts);
      queryClient.refetchQueries(APIQueryKeys.connectedAccountsSelection);
    },
    onError: () => dispatchAPIErrorToast(),
  });

  const handleResetConnectedAccount = (e) => {
    e.stopPropagation();
    renderAlertConfirmationModal(modalContext, {
      ...RemoveConnectedAccountsModalProps,
      onCTAButtonClick: () => {
        ResetConnectedAccount();
        modalContext.onModalClose();
      },
    });
  };

  return (
    <div
      className={`bank-account-item ${selected ? "selected" : ""} mt-sm-6 mt-2`}
      onClick={!selected ? saveAccountAsDefaultAccount.bind(this, { account_id: account.account_id }) : null}
    >
      <div className="brand-container">
        {!!account.logo_url_base64_encoded ? (
          <img
            src={`data:image/png;base64,${account.logo_url_base64_encoded}`}
            alt={`${account.institution_name} logo`}
            className="bank-brand-logo"
          />
        ) : (
          <div className="bank-brand-logo-placeholder">{account.institution_name}</div>
        )}
        {isResettingConnectedAccount ? (
          <CircularProgress />
        ) : (
          <button className="delete" onClick={handleResetConnectedAccount}>
            <Trash />
          </button>
        )}
      </div>
      <div className="divider"></div>
      <div className="account-details-container">
        <div className="account-details">
          <div className="account-name">{account.name ?? ""}</div>
          <div className="account-number">(...{account.mask ?? "****"})</div>
        </div>
        {selected && <Tick className="active-icon" />}
      </div>
    </div>
  );
};

export default function ConnectedAccounts() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { isFetching: isFetchingConnectedAccounts, data: connectedAccounts } = useConnectedAccounts();

  const { isFetching: isFetchingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const onSavingBank = () => {
    queryClient.refetchQueries(APIQueryKeys.connectedAccounts);
    queryClient.refetchQueries(APIQueryKeys.connectedAccountsSelection);
  };

  const { isSavingAccount, plaidReady, openPlaid, saveAccountAsDefaultAccount } = useConnectBankAccount({
    onSuccess: onSavingBank,
    onError: () => dispatchAPIErrorToast({ alignToAppContent: true }),
  });

  const connectedAccountItems = useMemo(() => {
    const accounts = connectedAccounts?.data?.data?.accounts || [];
    return accounts.map((account, index) => (
      <BankAccountItem
        key={account.account_id || index}
        account={account}
        selected={connectedAccountSelection?.data?.data?.selected === account.account_id}
        saveAccountAsDefaultAccount={saveAccountAsDefaultAccount}
      />
    ));
  }, [
    connectedAccounts?.data?.data?.accounts,
    connectedAccountSelection?.data?.data?.selected,
    saveAccountAsDefaultAccount,
  ]);

  const isConnected = useMemo(() => {
    return connectedAccountItems.length > 0;
  }, [connectedAccountItems]);

  const isFetching = isFetchingConnectedAccounts || isFetchingConnectedAccountSelection | isSavingAccount;

  return (
    <div className="connected-accounts">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button">
                <ArrowLeftCircle onClick={() => navigate("/banking")} />
              </button>
              <h3 className="section-title">Connected Accounts</h3>
              <button className={`add-button ${!plaidReady ? "invisible" : ""}`}>
                <AddCircle onClick={openPlaid} />
              </button>
            </div>
            {isFetching || (!isConnected && !plaidReady) ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : isConnected ? (
              <div className="connected-bank-accounts">{connectedAccountItems}</div>
            ) : (
              <div className="connect-your-bank">
                <EarnMore
                  className="mt-sm-6 mt-2"
                  title={`Transfer to your bank or debit\ncard`}
                  cardButtonText="Get Started"
                  CardIllustrationComponent={AddABank}
                  onCardButtonClick={openPlaid}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
