import { useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import { useModalContext } from "../../../Contexts/ModalContext";

import { modalVariants, numericDateFormat } from "../../../Utils/constants";

import { formatCurrency, formatDate } from "../../../Utils/helpers";

export default function renderAuthorizeTransfer(modalContext, props) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(AuthorizeTransfer, props);
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Authorize this transaction");
}

const AuthorizeTransfer = (props) => {
  const {
    stake_account_number_last4,
    stake_routing_number,
    selectedAccount,
    clearingTransactionDate,
    amount,
    AuthorizeCallback,
  } = props;
  const modalContext = useModalContext();

  const [isLoading, setIsLoading] = useState(false);

  const formatCurrentDate = useMemo(() => {
    return formatDate(new Date(), numericDateFormat);
  }, []);

  const handleAuthorize = () => {
    setIsLoading(true);
    AuthorizeCallback();
  };

  return (
    <>
      <span className="modal-content-text mt-sm-3 mt-2">
        By selecting authorize, I authorize <span className="bold-black-text">{formatCurrency(amount)}</span> to be
        debited on <span className="bold-black-text">{formatCurrentDate}</span> from my bank account below.
      </span>
      <span className="modal-content-text mt-6 mb-4">
        Your transfer is expected to arrive on {clearingTransactionDate}.
      </span>
      <div className="account-detail-direction-label mb-3">From</div>
      <div className="account-detail-card mb-sm-6 mb-4">
        <div className="account-detail">
          <div className="account-detail-label">{selectedAccount?.name}</div>
          <div className="account-detail-value">(...{selectedAccount?.mask ?? "XXXX"})</div>
        </div>
        <div className="account-detail">
          <div className="account-detail-label">Routing Number</div>
          <div className="account-detail-value">{selectedAccount?.routing ?? "XXXX"}</div>
        </div>
      </div>
      <div className="account-detail-direction-label mb-3">To</div>
      <div className="account-detail-card mb-sm-6 mb-5">
        <div className="account-detail">
          <div className="account-detail-label">Stake</div>
          <div className="account-detail-value">(...{stake_account_number_last4 ?? "XXXX"})</div>
        </div>
        <div className="account-detail">
          <div className="account-detail-label">Routing Number</div>
          <div className="account-detail-value">{stake_routing_number ?? "XXXX"}</div>
        </div>
      </div>
      <div className="action-container split-equally">
        <PrimaryButton className="secondary-colors" onClick={modalContext.onModalClose}>
          Cancel
        </PrimaryButton>
        <PrimaryButton className="blue-theme" onClick={handleAuthorize} disabled={isLoading}>
          Authorize {isLoading && <CircularProgress />}
        </PrimaryButton>
      </div>
    </>
  );
};
