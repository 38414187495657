import renderStatusModal from "../status-modal";

import { Close } from "../../../Assets/Icons";

export function renderPayrollAdditionFailure(modalContext, showIntercom) {
  const { onModalClose } = modalContext;

  renderStatusModal(modalContext, {
    StatusIcon: Close,
    statusClassName: "error",
    title: "Connection Failed",
    description:
      "We're sorry, but your request could not be processed. Please try again later or contact support for assistance",
    CTAButtonText: "Ok",
    secondaryCTAButtonText: "Contact Live Chat",
    onCTAButtonClick: () => onModalClose(),
    onSecondaryCTAButtonClick: () => {
      showIntercom();
      onModalClose();
    },
  });
}
