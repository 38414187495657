import { useNavigate } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import PrimaryButton from "../../../Components/Buttons/primary-button";

import useConnectBankAccount from "../../../CustomHooks/useConnectBankAccount";

import { dispatchAPIErrorToast } from "../../../Utils/helpers";
import { modalVariants } from "../../../Utils/constants";

export default function renderCashOutBankNotConnected(modalContext) {
  const { updateModalComponent, setModalVariant, setModalTitle, setModalClassName, onModalClose } = modalContext;

  updateModalComponent(() => {
    const navigate = useNavigate();

    const { isSavingAccount, plaidReady, openPlaid } = useConnectBankAccount({
      onSuccess: () => {
        navigate("/banking/connected-accounts");
        onModalClose();
      },
      onError: () => {
        dispatchAPIErrorToast({ alignToAppContent: true });
        onModalClose();
      },
      onInitiatingSave: () => setModalClassName(null),
      onExit: () => setModalClassName(null),
    });

    const handleAddBankClick = () => {
      if (!isSavingAccount && plaidReady) {
        setModalClassName("d-none");
        openPlaid();
      }
    };

    return (
      <>
        <span className="modal-content-text mb-6 mt-2">You need to add a bank to cash out.</span>
        <div className="action-container">
          <PrimaryButton className="blue-theme" onClick={handleAddBankClick} disabled={isSavingAccount || !plaidReady}>
            Add Bank {(isSavingAccount || !plaidReady) && <CircularProgress />}
          </PrimaryButton>
          <PrimaryButton className="secondary-colors" onClick={onModalClose}>
            Maybe later
          </PrimaryButton>
        </div>
      </>
    );
  });
  setModalVariant(modalVariants.infoDialog);
  setModalTitle("Add a bank to cash out");
}
