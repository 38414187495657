import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import AmountField from "../../Components/Form Fields/amount-field";
import PrimaryButton from "../../Components/Buttons/primary-button";

import renderStatusModal from "../../Organisms/Modals/status-modal";
import renderFeatureUnavailable from "../../Organisms/Modals/Banking/feature-unavailable";

import {
  useActivities,
  useConnectedAccounts,
  useConnectedAccountSelection,
  useDeals,
  useStakeDebit,
} from "../../CustomHooks/Queries";
import { useInitiatePlaidWithdrawal, useWithdrawal } from "../../CustomHooks/Mutations";

import withFormProvider from "../../HOC/withFormProvider";

import { useModalContext } from "../../Contexts/ModalContext";

import { dispatchErrorToast, formatCurrency, getTransferTypes, isSVDApproved } from "../../Utils/helpers";
import { FailedTransferStatusModalProps, SuccessfulTransferStatusModalProps } from "../../Utils/constants";

import { ArrowLeftCircle, Edit } from "../../Assets/Icons";

function CashOut() {
  const modalContext = useModalContext();

  const navigate = useNavigate();

  const { watch, handleSubmit } = useFormContext();

  const { isLoading: isLoadingDeals, data: deals } = useDeals();

  const { isLoading: isLoadingActivities, data: activitiesData } = useActivities();

  const { isLoading: isLoadingStakeDebit, data: stakeDebit } = useStakeDebit();

  const {
    isLoading: isLoadingConnectedAccounts,
    data: connectedAccounts,
    isError: isConnectedAccountsFetchError,
  } = useConnectedAccounts();

  const { isLoading: isLoadingConnectedAccountSelection, data: connectedAccountSelection } =
    useConnectedAccountSelection();

  const selectedAccount = useMemo(
    () =>
      connectedAccounts?.data?.data?.accounts?.find(
        (account) => account.account_id === connectedAccountSelection?.data?.data?.selected
      ) ??
      connectedAccounts?.data?.data?.accounts?.[0] ??
      null,
    [connectedAccounts?.data?.data?.accounts, connectedAccountSelection?.data?.data?.selected]
  );

  const SVDApproved = useMemo(() => {
    return isSVDApproved(stakeDebit?.data);
  }, [stakeDebit?.data]);

  const accountDescription = useMemo(() => {
    return `${selectedAccount?.name} (...${selectedAccount?.mask})`;
  }, [selectedAccount]);

  const dailyCashOurLimit = useMemo(() => {
    let dailyLimit = 100;
    if (SVDApproved) {
      dailyLimit = (stakeDebit?.data?.data?.account_limits?.daily_cash_out_limit ?? 0) / 100;
    } else {
      dailyLimit = deals?.data?.content?.transfer_out_limit ?? 1000;
    }
    return dailyLimit;
  }, [stakeDebit, deals, SVDApproved]);

  const transferTypes = useMemo(() => getTransferTypes(deals?.data), [deals]);

  useEffect(() => {
    if (isConnectedAccountsFetchError || (!isLoadingStakeDebit && !SVDApproved)) {
      navigate("/banking");
    }
  }, [isConnectedAccountsFetchError, isLoadingStakeDebit, SVDApproved, navigate]);

  const onWithDrawSuccess = () =>
    renderStatusModal(modalContext, {
      ...SuccessfulTransferStatusModalProps,
      description: `Your transfer is expected to arrive on ${deals?.data?.content?.transaction_clearing_date}.`,
      onCTAButtonClick: () => {
        modalContext.onModalClose();
        navigate("/banking");
      },
    });

  const onWithDrawError = () =>
    renderStatusModal(modalContext, {
      ...FailedTransferStatusModalProps,
      onCTAButtonClick: modalContext.onModalClose,
    });

  const { isLoading: isWithdrawing, mutate: Withdraw } = useWithdrawal({
    onSuccess: onWithDrawSuccess,
    onError: onWithDrawError,
  });

  const { isLoading: isInitiatingPlaidWithdrawal, mutate: InitiatePlaidWithdrawal } = useInitiatePlaidWithdrawal({
    onSuccess: () =>
      SVDApproved ? onWithDrawSuccess() : Withdraw({ amount: String(cashAmount), withdrawMode: "Stripe" }),
    onError: onWithDrawError,
  });

  const cashAmount = parseFloat(watch("cash-amount")?.unmasked ?? "");

  const disableSubmitButton = isNaN(cashAmount) || cashAmount === 0;

  const onSubmit = () => {
    if (!selectedAccount) {
      dispatchErrorToast("Please add a bank account to proceed", {
        alignToAppContent: true,
        key: "noConnectedAccount",
      });
    } else if (dailyCashOurLimit > 0 && cashAmount > dailyCashOurLimit) {
      dispatchErrorToast("Amount cannot exceed the daily limit.", {
        alignToAppContent: true,
        key: "amountExceedsThreshold",
      });
    } else if (cashAmount > activitiesData?.data?.data?.currentBalance) {
      dispatchErrorToast("Amount exceeds what you have available to transfer.", {
        alignToAppContent: true,
        key: "amountExceedsThreshold",
      });
    } else {
      InitiatePlaidWithdrawal({
        acctName: selectedAccount.name,
        acctId: selectedAccount?.account_id,
        amount: cashAmount,
      });
    }
  };

  const handleChangeTransferType = (selectedType) => {
    selectedType === transferTypes[0].type && renderFeatureUnavailable(modalContext, "Instant Transfer");
  };

  const isLoading =
    isLoadingDeals ||
    isLoadingActivities ||
    isLoadingStakeDebit ||
    isLoadingConnectedAccounts ||
    isLoadingConnectedAccountSelection;

  return (
    <div className="cash-out">
      <section className="card-section-wrapper">
        <div className="max-width-container">
          <div className="card-section">
            <div className="section-header">
              <button className="go-back-button" onClick={navigate.bind(this, -1)}>
                <ArrowLeftCircle />
              </button>
              <h3 className="section-title">Cash Out</h3>
              <div className="dummy"></div>
            </div>
            {isLoading ? (
              <div className="loading-content">
                <CircularProgress />
              </div>
            ) : (
              <form className="content mt-sm-12 mt-6" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="top-container">
                  <AmountField />
                  {!!dailyCashOurLimit && (
                    <span className="daily-limit-label mt-2">Daily limit: {formatCurrency(dailyCashOurLimit)}</span>
                  )}
                </div>
                <div className="bottom-container">
                  {deals?.data?.data?.currentDeal?.enabled_for_instant_transfer__c && (
                    <div className="transfer-types mb-6">
                      {transferTypes.map((transferType, idx) => (
                        <button
                          key={idx}
                          className={`transfer-type ${transferType.type}${
                            transferType.type === transferTypes[1].type ? " selected" : ""
                          }`}
                          type="button"
                          onClick={handleChangeTransferType.bind(this, transferType.type)}
                        >
                          <span className="icon-wrapper">
                            <transferType.Icon className="icon" />
                          </span>
                          <div className="title">{transferType.title}</div>
                          <span className="description">{transferType.description}</span>
                        </button>
                      ))}
                    </div>
                  )}
                  <div className="details">
                    <div className="detail-row">
                      <span className="detail-label">Account</span>
                      <span className="detail-value">
                        {!!selectedAccount ? accountDescription : "Connect a bank account"}
                        <button
                          className="edit"
                          type="button"
                          onClick={navigate.bind(this, "/banking/connected-accounts")}
                        >
                          <Edit />
                        </button>
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="detail-label">Fee</span>
                      <span className="detail-value">Free</span>
                    </div>
                    <div className="detail-row">
                      <div className="info">
                        2-3 business days. Transactions made after 3:00 PM ET or on a weekend or holiday will be
                        processed the next business day.
                      </div>
                    </div>
                  </div>
                  <PrimaryButton
                    className="blue-theme mt-sm-6 mt-4"
                    type="submit"
                    disabled={disableSubmitButton || isInitiatingPlaidWithdrawal || isWithdrawing}
                  >
                    Transfer Cash {(isInitiatingPlaidWithdrawal || isWithdrawing) && <CircularProgress />}
                  </PrimaryButton>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default withFormProvider(CashOut);
