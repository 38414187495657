import { useViewport } from "../../../Contexts/ViewportContext";

import { mediumScreenStartFrom, modalVariants } from "../../../Utils/constants";

import { DownloadStakeMobileAppQRCode } from "../../../Assets/Images";

export default function renderFeatureUnavailable(modalContext, featureName) {
  const { updateModalComponent, setModalVariant, setModalTitle } = modalContext;

  updateModalComponent(() => {
    const { width } = useViewport();

    return (
      <>
        <span className="modal-content-text mb-5 mt-2">To use this feature, please use our mobile app</span>
        {width <= mediumScreenStartFrom ? (
          <div className="action-container">
            <a
              className="primary-button blue-theme text-decoration-none"
              href="https://www.stake.rent/download"
              target="_blank"
              rel="noreferrer"
            >
              Open the mobile app
            </a>
          </div>
        ) : (
          <>
            <img
              className="download-stake-mobile-app-qrcode mx-auto mb-5"
              src={DownloadStakeMobileAppQRCode}
              alt="Download Stake Mobile App QRCode"
            />
            <span className="text-lg-regular color--charcoal text-center">
              scan or visit{" "}
              <a className="color--stake-blue" href="https://www.stake.rent/download" target="_blank" rel="noreferrer">
                stake.rent/download
              </a>
              <br></br>on your mobile device
            </span>
          </>
        )}
      </>
    );
  });
  setModalVariant(modalVariants.infoDialog);
  setModalTitle(`${featureName} is currently not available in the web app`);
}
